import { useEffect, useState, cloneElement } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

const View = ({
  name,
  view,
  children,
  isFullHeight,
  showHeading,
  strings,
  title,
  description,
  languageIso,
}) => {
  const [header, setHeader] = useState(undefined);
  const [footer, setFooter] = useState(undefined);
  const [toolStrings, setToolStrings] = useState({});
  const baseClass =
    "container-fluid navbar-padding d-flex flex-grow-1 flex-column";
  const rootClass = isFullHeight
    ? `${baseClass} overflow-auto container-full-height`
    : baseClass;
  useEffect(() => {
    const loadText = async () => {
      try {
        const stringsFile = `${view}.strings.${languageIso}.json`;
        const toolStrings = await import(`${stringsFile}`);
        setToolStrings(toolStrings);
        const { default: json } = toolStrings;
        const { header, footer } = json;
        setHeader(header);
        setFooter(footer);
      } catch (err) {
        // The text.json file is optional and this will just catch the cases wher,e it can't find test.json.
      }
    };
    loadText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* TODO: Facebook open graph tags
  <meta property="og:url"  
<meta property="og:type" content="article" />
<meta property="og:image"
*/

  const toolTitle = title || `${strings.SiteTitle} - ${name}`;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{toolTitle}</title>
        <meta property="og:title" content={toolTitle} />
        {description && <meta name="description" content={description} />}
        {description && <meta name="og:description" content={description} />}
      </Helmet>
      <div className={rootClass}>
        {(showHeading === undefined || showHeading === true) && (
          <h1 className="pt-2">{name}</h1>
        )}
        {header && <ReactMarkdown>{header}</ReactMarkdown>}
        <div className={"d-flex flex-grow-1 flex-shrink-1"}>
          {cloneElement(children, { toolStrings })}
        </div>
      </div>
      <div style={{ display: "contents" }}>
        {footer && <ReactMarkdown>{footer}</ReactMarkdown>}
      </div>
    </>
  );
};

export default View;
