import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const AppContainer = ({ history, children, onPathNameChange }) => {
  useEffect(() => {
    history.listen(({ pathname }) => {
      onPathNameChange(pathname);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  return <>{children}</>;
};

export default withRouter(AppContainer);
