import { useEffect, useState, Suspense, lazy, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import enTools from "./tools/tools.en.json";
import enStrings from "./strings/strings.en.json";

import SideBarList from "./SideBarList";
import View from "./View";
import AppContainer from "./AppContainer";
import axios from "axios";
import languages from "./languages.json";

const serviesBaseUrl = "https://services.calistools.com/";
if (process.env.NODE_ENV === "production") {
  const { defaults } = axios;
  defaults.baseURL = serviesBaseUrl;
}

const App = () => {
  const { pathname: currentPathName } = window.location;

  const [modules, setModules] = useState([]);
  const [pathName, setPathName] = useState(currentPathName);
  const [tools, setTools] = useState(enTools);
  const [strings, setStrings] = useState(enStrings);
  const path = useMemo(() => pathName.replace(/^\/+|\/+$/g, ""), [pathName]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const hasLanguagePrefix = useMemo(
    () => path.length === 2 || path.match(/^[A-Za-z]{2}\//),
    [path]
  );
  const languageIso = useMemo(
    () => (hasLanguagePrefix ? path.substring(0, 2) : "en"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPathName, hasLanguagePrefix]
  );

  useEffect(() => {
    const loadTools = async () => {
      const toolImport = `./tools/tools.${languageIso}.json`;
      setTools(
        languageIso === "en" ? enTools : (await import(`${toolImport}`)).default
      );
    };
    const loadStrings = async () => {
      const stringsImport = `./strings/strings.${languageIso}.json`;
      setStrings(
        languageIso === "en"
          ? enStrings
          : (await import(`${stringsImport}`)).default
      );
    };
    loadTools();
    loadStrings();
  }, [languageIso]);

  const flagCode = useMemo(
    () => languages.find(({ iso }) => iso === languageIso)?.flag ?? "au",
    [languageIso]
  );

  useEffect(() => {
    const loadData = () => {
      const items = (tools?.items || [])
        .reduce((a, c) => [...a, ...c.items], [])
        .map((t) => getModule(t));

      setModules([getModule(tools), ...items]);
    };
    loadData();
  }, [tools]);

  const getModule = ({
    name,
    url,
    view,
    isFullHeight,
    showHeading,
    title,
    description,
    args,
  }) => {
    const NewView = lazy(() => import(`${view}`));
    return {
      url,
      name,
      view,
      Module: NewView,
      isFullHeight,
      showHeading,
      title,
      description,
      args,
    };
  };

  const stringsLookup = useMemo(
    () => strings.reduce((a, { name, value }) => ({ ...a, [name]: value }), {}),
    [strings]
  );

  return (
    <Router>
      <AppContainer onPathNameChange={(path) => setPathName(path)}>
        <div
          className="offcanvas offcanvas-start bg-dark h-100"
          tabIndex="-1"
          id="offcanvas"
          aria-labelledby="offcanvasLabel"
        >
          <div className="offcanvas-header">
            <h5 className="navbar-brand primary text-white">
              {" "}
              {stringsLookup?.SiteTitle ?? "Cali's tools!!"}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <SideBarList
              tools={tools}
              languageIso={languageIso}
              strings={stringsLookup}
            />
          </div>
        </div>

        {/* TODO: set full screen mode here */}

        <div className="navbar fixed-top d-flex align-items-center w-100 bg-dark p-0">
          <Link
            data-bs-toggle="offcanvas"
            href="#offcanvas"
            data-bs-target=".offcanvas"
            role="button"
            className="btn shadow-none"
            to="/"
          >
            <i className="fa fa-bars fa-lg m-2 text-light" />
          </Link>
          <Link
            to={languageIso === "en" ? "/" : `/${languageIso}`}
            className="navbar-brand text-light "
          >
            {stringsLookup?.SiteTitle ?? "Cali's tools!!"}
          </Link>
          <div className="flex-grow-1" />
          <div className="dropdown">
            <button
              className="btn btn-dark dropdown-toggle shadow-none"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <img
                src={`/images/flags/${flagCode}.svg`}
                height="36"
                width="36"
                alt="English"
              />
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end bg-light"
              aria-labelledby="dropdownMenuLink"
            >
              {languages.map(({ flag, iso, name }) => (
                <li key={iso}>
                  <Link
                    className="dropdown-item"
                    to={
                      hasLanguagePrefix
                        ? `/${iso}/${path.substring(3, path.length)}`
                        : `/${iso}/${path}`
                    }
                  >
                    <img
                      src={`/images/flags/${flag}.svg`}
                      height="30"
                      width="30"
                      alt="English"
                    />
                    <span className="m-2">{name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <Suspense fallback={<div>Loading..</div>}>
          <Switch>
            {modules.map(
              ({
                url,
                name,
                Module,
                view,
                showHeading,
                isFullHeight = false,
                title,
                description,
                args,
              }) => {
                return [{ iso: "" }, ...languages].map(({ iso }) => {
                  const newUrl = iso ? `/${iso}${url}` : url;
                  return (
                    <Route
                      key={newUrl}
                      path={newUrl}
                      strings={stringsLookup}
                      exact
                      render={(props) => (
                        <View
                          name={name}
                          view={view}
                          strings={stringsLookup}
                          showHeading={showHeading}
                          languageIso={languageIso}
                          isFullHeight={isFullHeight}
                          title={title}
                          description={description}
                        >
                          <Module
                            serviesBaseUrl={serviesBaseUrl}
                            languageIso={languageIso}
                            strings={stringsLookup}
                            tools={tools}
                            args={args}
                            {...props}
                          />
                        </View>
                      )}
                    />
                  );
                });
              }
            )}
          </Switch>
        </Suspense>
      </AppContainer>
    </Router>
  );
};

export default App;
