import { Link } from "react-router-dom";

const SideBarList = ({ tools: { items = [] }, languageIso }) => (
  <ul className="nav flex-column flex-nowrap text-truncate">
    {items.map(({ name, items: childItems = [] }) => (
      <li key={name} className="nav-item">
        <a
          className="nav-link"
          data-bs-toggle="collapse"
          href={`#${name}Collapse`}
          aria-expanded="true"
          aria-controls={`#${name}Collapse`}
        >
          {name}
        </a>
        <ul
          className="collapse show nav flex-column flex-nowrap"
          id={`${name}Collapse`}
        >
          {childItems
            .filter(
              ({ showInListings }) =>
                showInListings === undefined || showInListings === true
            )
            .map(({ name, url }) => (
              <li key={`/${languageIso}${url}`} className="nav-item ps-4">
                <Link
                  className="nav-link bg-close bg-dark p-1"
                  data-bs-dismiss="offcanvas"
                  to={`/${languageIso}${url}`}
                >
                  {name}
                </Link>
              </li>
            ))}
        </ul>
      </li>
    ))}
  </ul>
);

export default SideBarList;
